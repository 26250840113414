import React, { Component } from "react";
// import "../css/App.scss";
import Social from "./Social";
import { TimelineMax, TweenMax, ScrollToPlugin } from "gsap";

class Home extends Component {
  constructor() {
    super();
    this.bounceArrow = this.bounceArrow.bind(this);
    this.scrollToBeast = this.scrollToBeast.bind(this);
    this.getScrollOffSet = this.getScrollOffSet.bind(this);
    this.getCurrentScreenWidth = this.getCurrentScreenWidth.bind(this);
    this.animateSocialIcons = this.animateSocialIcons.bind(this);
  }

  animateSocialIcons() {
    const socialIcons = document.querySelectorAll("#home .social-icon");
    const t = new TimelineMax();
    t.set(socialIcons, { scale: 2, transformOrigin: "50% 50%" });
    t.staggerTo(socialIcons, 2, {
      opacity: 1,
      scale: 1,
    }, 0.1);
  }

  scrollToBeast() {
    // const beast = document.getElementById("beast");
    // const offset = this.getScrollOffSet();
    // console.log(offset);
    // // TweenMax.to(window, 1, { scrollTo: { y: beast, offsetY: offset } });
    // TweenMax.to(beast, 2, { scrollTo: 250 });
  }

  getScrollOffSet() {
    const screenWidth = this.getCurrentScreenWidth();
    let offset = 0;
    if (screenWidth >= 1000) {
      offset = -300;
    }
    return offset;
  }

  getCurrentScreenWidth() {
    let dpr;
    if (window.devicePixelRatio !== undefined) {
      dpr = window.devicePixelRatio;
    } else {
      dpr = 1;
    }
    const screenWidth = window.innerWidth * dpr;
    return screenWidth;
  }

  bounceArrow() {
    const arrow = document.getElementById("arrow");
    var bounceTime = 2;
    var t = new TimelineMax({ repeat: -1, repeatDelay: 0 });
    TweenMax.to(arrow, 0.5, {
      opacity: 1,
    });

    t.to(arrow, bounceTime, {
      y: +20,
      ease: "Power2.easeOut",
    })
      .to(arrow, bounceTime / 2, {
        y: 0,
        ease: "Bounce.easeOut",
        delay: bounceTime / 4,
      });
  }
  componentDidMount() {
    const letterS = document.getElementById("selk-letter-s");
    const eBottom = document.getElementById("e-bottom");
    const eMiddle = document.getElementById("e-middle");
    const eTop = document.getElementById("e-top");
    const lVertical = document.getElementById("l-vertical");
    const lHorizontal = document.getElementById("l-horizontal");
    const kLine = document.getElementById("k-line");
    const kTop = document.getElementById("k-top");
    const kBottom = document.getElementById("k-bottom");
    const logo = document.getElementById("logo-home");
    const socialIcons = document.querySelectorAll("#home .social-icon");

    // constants for GSAP animation
    const myEase = "Power4.easeOut";
    const tweenTime = 0.3;
    const tweenDelay = "-=0.2";
    const loader = document.querySelector(".loader");
    const spinnerWrapper = document.querySelector(".spinner-wrapper");

    var t = new TimelineMax({});

    t.set(logo, { scale: 2, y: +60 });
    t.set(letterS, { drawSVG: "0%" });
    t.set(eTop, { y: -20, scaleX: 1.5 });
    t.set(eMiddle, { x: 30, scaleX: 1.5, rotation: 0 });
    t.set(eBottom, { y: -20, scaleX: 1.5 });
    t.set(lHorizontal, { rotation: -90 });
    t.set(lVertical, { y: -30 });
    t.set(kTop, { rotation: -90 });
    t.set(kBottom, { rotation: -90 });
    t.set(kLine, { y: -40 });

    t.set(socialIcons, { scale: 2, transformOrigin: "50% 50%" });

    t.to(loader, 1, {
      opacity: 0,
      display: "none",
    })
      .to(".container-home", 0.2, {
        opacity: 1,
      }, "-=0.5")
      .to(letterS, 0.3, {
        opacity: 1,
        drawSVG: "100%",
      })
      .to(lHorizontal, tweenTime, {
        opacity: 1,
        rotation: 0,
        ease: myEase,
      }, tweenDelay)
      .to(eTop, tweenTime, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: myEase,
      }, tweenDelay)
      .to(eBottom, tweenTime, {
        opacity: 1,
        y: 0,
        ease: myEase,
        scale: 1,
      }, tweenDelay)
      .to(lVertical, tweenTime, {
        opacity: 1,
        rotation: 0,
        y: 0,
        ease: myEase,
      }, tweenDelay)
      .to(eMiddle, tweenTime, {
        opacity: 1,
        x: 0,
        rotation: 0,
        scale: 1,
        ease: myEase,
      }, tweenDelay)
      .to(kBottom, tweenTime, {
        opacity: 1,
        rotation: 0,
        ease: myEase,
      }, tweenDelay)
      .to(kTop, tweenTime, {
        opacity: 1,
        rotation: 0,
        ease: myEase,
      }, tweenDelay)
      .to(kLine, tweenTime, {
        opacity: 1,
        y: 0,
        ease: myEase,
      }, tweenDelay)
      .to(logo, 1, {
        scale: 1,
        y: 20,
        ease: myEase,
      }, tweenDelay)
      .staggerTo(
        ".f",
        1.5,
        {
          opacity: 1,
          ease: myEase,
          y: +40,
        },
        0.15,
        "-=1",
      )
      .staggerTo(
        socialIcons,
        0.5,
        {
          opacity: 1,
          scale: 1,
          ease: myEase,
        },
        0.2,
        "-=1.5",
      );

    setTimeout(this.bounceArrow, 2000);
  }
  render() {
    return (
      <section id="home">
        <Social />
        <div className="container container-home">
          <svg id="logo-home" viewBox="0 0 134.54 38.37">
            <title>SELK Logo</title>
            <rect
              id="e-bottom"
              className="selk-letter"
              x="27.24"
              y="30.69"
              width="26.8"
              height="5.9"
            />
            <rect
              id="e-middle"
              className="selk-letter"
              x="27.24"
              y="16.09"
              width="26.8"
              height="5.9"
            />
            <rect
              id="e-top"
              className="selk-letter"
              x="27.24"
              y="1.49"
              width="26.8"
              height="5.9"
            />
            <rect
              id="l-horizontal"
              className="selk-letter"
              x="76.64"
              y="30.69"
              width="20.6"
              height="5.9"
            />
            <rect
              id="l-vertical"
              className="selk-letter"
              x="70.44"
              y="1.49"
              width="6.2"
              height="35.1"
            />
            <polygon
              id="k-bottom"
              className="selk-letter"
              points="134.54 33.49 119.54 18.99 115.24 22.29 130.14 37.69 134.54 33.49"
            />
            <polygon
              id="k-top"
              className="selk-letter"
              points="129.84 0.39 134.24 4.49 119.54 18.99 115.24 22.29 115.24 15.79 129.84 0.39"
            />
            <rect
              id="k-line"
              className="selk-letter"
              x="109.04"
              y="1.49"
              width="6.2"
              height="35.2"
            />
            <path
              id="selk-letter-s"
              className="selk-letter-s"
              d="M1.82,35.69s10.37-4,5.84-12.21C2.81,14.7-1.07,7.77,9.91,2.61"
              transform="translate(0.04 -0.01)"
            />
          </svg>
          <h3 className="f ">NEW ALBUM</h3>
          <h3 className="f ">SHED THE SKIN</h3>
          <h3 className="f ">OUT NOW</h3>
          <svg
            onClick={this.scrollToBeast}
            version="1.1 "
            id="arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
          >
            <g>
              <line
                x1="0"
                y1="0"
                x2="24"
                y2="24"
                stroke="black "
                strokeWidth="3"
              />
              <line
                x1="24"
                y1="24"
                x2="48"
                y2="0"
                stroke="black "
                strokeWidth="3"
              />
            </g>
          </svg>
        </div>
      </section>
    );
  }
}

export default Home;
