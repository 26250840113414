import React, { Component } from 'react';
import store from '../icon/social-store.svg';
import twitter from '../icon/social-twitter.svg';
import facebook from '../icon/social-facebook.svg';
import spotify from '../icon/social-spotify.png';
import instagram from '../icon/social-instagram.png';


class Social extends Component {
  render() {
    return (
      <div className="social">
        <a href="https://open.spotify.com/artist/2XQEmjq2NVlPxAhjRanGY6">
          <img alt="spotify-link" className="social-icon social-anim" src={spotify} />
        </a>
        <a href="https://www.instagram.com/selkmusic/">
          <img alt="instagram-link" className="social-icon social-anim" src={instagram} />
        </a>
        <a href="https://www.facebook.com/selkmusic/">
          <img alt="facebook-link" className="social-icon social-anim" src={facebook} />
        </a>
        <a href="https://twitter.com/SELKMUSIC">
          <img alt="twitter-link" className="social-icon social-anim" src={twitter} />
        </a>
        <a href="https://selk.bandcamp.com/releases">
          <img alt="store-link" className="social-icon social-anim" src={store} />
        </a>
      </div>
    );
  }
}

export default Social;
