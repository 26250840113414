import shortid from 'shortid';
import React, { Component, Fragment } from "react";
import Home from "./Home";
import Shed from "./releases/ShedTheSkin";
import LastPage from './LastPage';
import Press from "./Press";
// import Video from "./unused/Video";
// import Music from "./unused/Music";
const eventsData = require("../events.json");

class App extends Component {
  constructor() {
    super();
    this.makeKeysForEvents = this.makeKeysForEvents.bind(this);
    this.state = {
      events: this.makeKeysForEvents(eventsData)
    };
  }

  addEvent(event) {
    const events = [...this.state.events];
    event.key = shortid.generate();
    events.push(event);
    this.setState({ events });
  }

  makeKeysForEvents(events) {
    events.forEach((event) => {
      event.key = shortid.generate();
    });
    return events;
  }

  render() {
    return (
      <Fragment>
        <Home />
        <Shed />
        {/* <Video /> */}
        {/* <Music /> */}
        <Press />
        {/* <Events events={this.state.events} /> */}
        <LastPage />
      </Fragment>
    );
  }
}

export default App;
