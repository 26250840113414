import React, { Component } from "react";
import App from "./App";
import { TweenMax } from "gsap";

class Root extends Component {
  componentDidMount() {
    const loader = document.querySelector(".loader");
    TweenMax.to(loader, 0.5, {
      opacity: 0,
      display: "none"
    });
  }
  render() {
    const test = "test";
    return (
      <App />
    );
  }
}
export default Root;
