import React from 'react';
import SocialFooter from './SocialFooter';
import '../css/App.scss';
import bandImg from '../images/band-texture.jpg';
import amsLogo from '../images/ams-logo-2.png';

const LastPage = (props) => {
  return (
    <section id="contact">
      <div className="ams-logo-wrapper">
        <h4>Bookings</h4><a href="mailto:ams.distribution2016@gmail.com"><img alt="AMS" id="ams" src={amsLogo} /></a>
      </div>
      <img alt="SELK" src={bandImg} id="band-img" className="img-fluid center-block" />
      <SocialFooter />
    </section>
  )
}

export default LastPage;