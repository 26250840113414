import React, { Component } from "react";
import img from "../../images/shed-the-skin-cover-artwork.jpeg";

class Shed extends Component {
    render() {
        return (
            <section id="beast">
                <div className="container">
                    <img alt="SELK Shed The Skin" className="img-beast" src={img} />
                    <a href="https://awal.ffm.to/selkshedtheskin" className="latest-single-link">
                        <button className="btn-beast">Listen Here</button>
                    </a>
                </div>
            </section>
        );
    }
}

export default Shed;
