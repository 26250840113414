import React, { Component } from 'react';
import amazon from '../icon/social-amazon.svg';
import store from '../icon/social-store.svg';
import itunes from '../icon/social-itunes.svg';
import twitter from '../icon/social-twitter.svg';
import facebook from '../icon/social-facebook.svg';
import spotify from '../icon/social-spotify.png';
import instagram from '../icon/social-instagram.png';

class SocialFooter extends Component {
    render() {
        return (
            <div className="social">
                <a href="https://open.spotify.com/artist/2XQEmjq2NVlPxAhjRanGY6">
                    <img alt="spotify-link" className="social-icon social-anim" src={spotify} />
                </a>
                <a href="https://www.instagram.com/selkmusic/">
                    <img alt="instagram-link" className="social-icon social-anim" src={instagram} />
                </a>
                <a href="https://www.facebook.com/selkmusic/">
                    <img alt="facebook-link" className="social-icon social-anim" src={facebook} />
                </a>
                <a href="https://twitter.com/SELKMUSIC">
                    <img alt="twitter-link" className="social-icon social-anim" src={twitter} />
                </a>
                <a href="https://selk.bandcamp.com/releases">
                    <img alt="store-link" className="social-icon social-anim" src={store} />
                </a>
                <a href="https://itunes.apple.com/us/album/beast/id1099447133">
                    <img alt="itunes-link" className="social-icon social-anim" src={itunes} />
                </a>
                <a href="https://www.amazon.co.uk/Beast-Selk/dp/B01HHJGMXU">
                    <img alt="itunes-link" className="social-icon social-anim" src={amazon} />
                </a>
            </div>
        );
    }
}

export default SocialFooter;