import React from 'react';

const Press = () => {
	return(
		    <div className="press">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <h2>&quot;Drawing from a broad sonic palette SELK’s raw, unshackled songs possess an inherent power which prove mesmerising in a live environment&quot;</h2>
                    <h6><a href="http://www.hotpress.com/Selk/news/LIVE-REVIEW-SELK--The-Grand-Social/17857836.html">Hotpress Gig Review, 2016</a></h6>
                </div>
                <div className="col-md-6">
                    <h2>&quot;A remarkably cohesive record for a debut &hellip;Beast is a journey worth taking.&quot;</h2>
                    <h6><a href="https://thelastmixedtape.com/2016/07/14/review-selk-beast/">The Last Mixtape, 2016</a></h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h2>&quot; &hellip;a moody and expansive work that melds indie-pop choruses with bold soundscapes and intricate production&quot;</h2>
                    <h6><a href="http://theoutmost.com/entertainment/music/soundbytes-august-2016/">The Outmost, 2016</a></h6>
                </div>
                <div className="col-md-6">
                    <h2>&quot; &hellip;an atmospheric, evocative, and at times, sinister collection. Beast is well worth grappling with.&quot;</h2>
                    <h6><a href="http://www.hotpress.com/archive/17702736.html">Hotpress Album Review, 2016</a></h6>
                </div>
            </div>
        </div>
    </div>
	)
}

export default Press;